import {Button, Dialog, Flex, String, withSnackbars} from "@hps/hops-react";
import {CheckoutService} from "@hps/hops-sdk-js";
import React, {useEffect, useState} from "react";

import BasketExpirationTimer from "Basket/BasketExpirationTimer.js";
import dBasketIdentity from "Dispatchers/dBasketIdentity.js";
import dBasketIdentityOffline from "Dispatchers/dBasketIdentityOffline";
import dBasketReset from "Dispatchers/dBasketReset.js";
import withBasket from "Hoc/withBasket.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality";

/**
 * Dialog handling impending basket expiration
 * 
 * @package HOPS
 * @subpackage Basket
 * @copyright Heritage Operations Processing Limited
 */
const BasketExpirationDialog = props => {

	const [basketRemainingLifetime, setBasketRemainingLifetime] = useState(null);
	const [extending, setExtending] = useState(false);
	const isExpired = basketRemainingLifetime <= 1;
	const isExpiring = ((basketRemainingLifetime !== null) && (basketRemainingLifetime <= 30));

	useEffect(() => {

		const updateBasketRemainingLifetime = () => {
			setBasketRemainingLifetime(props.BasketIdentity?.Expiry ? Math.max(((props.BasketIdentity?.Expiry || 0) - Math.floor((Date.now() / 1000))), 0) : null);
		};

		// Set an interval to calculate the remaining basket lifetime every second
		const remainingLifetimeUpdateInterval = setInterval(updateBasketRemainingLifetime, 1000);

		// Clean up function, remove the interval
		return () => clearInterval(remainingLifetimeUpdateInterval);

	}, [props.BasketIdentity]);


	const handleNewSale = () => {
		dBasketReset();
		setBasketRemainingLifetime(null);
	};


	const handleExtend = async () => {

		setExtending(true);

		try {

			if (!props.ReducedFunctionality) {
				const basket = await CheckoutService.extendBasketLifetime(props.BasketIdentity?.Id);
				dBasketIdentity(basket?.Id, basket?.Expiry);
			}
			else {
				dBasketIdentityOffline();
			}

		}
		catch (e) {
			props.snack(e);
		}

		setExtending(false);

	};

	return (
		<Dialog
			gap={2}
			noCloseButton={true}
			noTransition={true}
			open={isExpiring}
			width="xs">
			<String
				bold={true}
				centre={true}
				noFlex={true}
				str="Basket Expiry"
				variant="h6" />
			<BasketExpirationTimer
				centre={true}
				prefix="The basket expires in"
				expiredText="The basket has expired." />
			<Flex justifyContent="flex-end">
				<Button
					label="New Sale"
					onClick={handleNewSale}
					size="large"
					variant={isExpired ? "contained" : "text"} />
				<Button
					disabled={isExpired}
					label="Extend Reservation"
					loading={extending}
					onClick={handleExtend}
					size="large"
					variant="contained" />
			</Flex>
		</Dialog>
	);

};

export default withBasket(withReducedFunctionality(withSnackbars(BasketExpirationDialog)));
