import {Animate, Banner, Button, Flex, IconButton, String, Table} from "@hps/hops-react";
import {PriceUtils} from "@hps/hops-sdk-js";
import moment from "moment/moment.js";
import PropTypes from "prop-types";
import {useState} from "react";

import ConfirmationDialog from "Components/ConfirmationDialog";
import dOrderSynced from "Dispatchers/dOrderSynced";
import withOrders from "Hoc/withOrders.js";
import withReducedFunctionality from "Hoc/withReducedFunctionality";
import OrderService from "Services/OrderService";

import SyncIconProgress from "@mui/icons-material/Autorenew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from "@mui/icons-material/Download";
import RefreshIcon from "@mui/icons-material/Refresh";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";

/**
 * Recent Orders view
 * 
 * @package HOPS
 * @subpackage Settings
 * @copyright Heritage Operations Processing Limited
 */
const UploadQueueView = props => {

	const uploadQueueLength = props.Orders?.UploadQueue?.length;
	const uploadQueueMaxRetries = props.Orders?.UploadQueue?.filter(q => q.RetryCount >= 10).length;

	const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

	const tableFields = [
		{
			label: "Created",
			render: i => (new moment(i.Timestamp * 1000)).format("DD/MM/YYYY HH:mm:ss")
		},
		{
			label: "Items",
			render: i => i.Items?.length
		},
		{
			label: "Sub Total",
			render: i => PriceUtils.getDisplayStringIntl(i.Items?.reduce((subTotal, item) => subTotal + ((item.Price) * item.Quantity), 0),)
		},
		{
			label: "Change",
			render: i => <String
				color={i.PaymentsChange > 0 ? "error" : undefined}
				str={PriceUtils.getDisplayStringIntl(i.Payments?.filter(p => !!p.PaymentProcessorData?.Change).map(p => p.PaymentProcessorData).reduce((n, {Change}) => n + Change, 0))} />
		},
		{
			label: "Upload Attempts",
			render: i => <String
				color={i.RetryCount >= 10 ? "error" : undefined}
				str={i.RetryCount} />
		}
	];

	if (props.adminActions) {
		tableFields.push({
			label: "Actions",
			render(i) {
				return (
					<>
						{props.adminActions && <Flex
							columnar={true}
							justifyContent="space-evenly"
							gap={0}>
							<IconButton
								color="error"
								icon={DeleteIcon}
								onClick={setConfirmationDialogOpen}
								tooltip="Delete Forever"
								value={i} />
						</Flex>}
					</>
				);
			}
		});
	}

	const handleConfirmationDialogClose = () => {
		setConfirmationDialogOpen(false);
	};

	const handleConfirmationDialogSuccess = () => {
		handleConfirmationDialogClose();

		// delete the order

	};

	/**
	 * Get the icon component to render.
	 * 
	 * @return {ReactNode}
	 */
	const syncIcon = () => {
		if (props.Orders?.Syncing) return <Animate animation="spin" component={SyncIconProgress} />;
		else if (uploadQueueLength) return <SyncProblemIcon />;
		else return <CheckCircleIcon />;
	};

	const handleSync = async () => {
		await OrderService.sync();
	};

	const handleClearState = () => {
		dOrderSynced(props.Orders?.UploadQueue);
	};

	const handleDownloadOrders = () => {

		const blob = new Blob([JSON.stringify(props.Orders.UploadQueue)], {type: "application/json"});

		const a = document.createElement("a");
		a.download = `orders.json`;
		a.href = window.URL.createObjectURL(blob);

		const clickEvt = new MouseEvent("click", {
			view: window,
			bubbles: true,
			cancelable: true
		});

		a.dispatchEvent(clickEvt);
		a.remove();

	};

	return (
		<Flex>

			{!!uploadQueueLength &&
				<Banner
					str="These sales have not been uploaded to HOPS."
					severity="warning" />}

			{!!uploadQueueMaxRetries &&
				<Banner
					str="One or more orders have upload errors. Orders which fail to upload 10 or more times will not be retried."
					severity="error" />}

			<Flex columnar={true}>
				<Button
					disabled={!uploadQueueLength || props.Orders?.Syncing}
					onClick={handleSync}
					label="Upload Now"
					startIcon={syncIcon} />
				<Button
					color="secondary"
					onClick={handleDownloadOrders}
					label="Download Orders"
					startIcon={DownloadIcon}
					variant="text" />
				<Button
					color="secondary"
					onClick={handleClearState}
					label="Refresh State"
					startIcon={RefreshIcon}
					variant="text" />
			</Flex>

			<Table
				items={props.Orders.UploadQueue.slice(0, 25)}
				fields={tableFields} />

			{uploadQueueLength > 25 && <String color="textSecondary" str="Showing items 1-25." />}
			{!uploadQueueLength && <String color="textSecondary" str="The upload queue is empty" />}

			<ConfirmationDialog
				onClose={handleConfirmationDialogClose}
				onSuccess={handleConfirmationDialogSuccess}
				open={confirmationDialogOpen}
				message={["You should only do this if you have already downloaded a copy.", "This CANNOT be undone."]}
				title="Are you sure you want to remove this order?" />
		</Flex>
	);

};

UploadQueueView.propTypes = {
	adminActions: PropTypes.bool
};

UploadQueueView.defaultProps = {
	adminActions: false
};

export default withOrders(withReducedFunctionality(UploadQueueView));
