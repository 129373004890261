import {Animate, AppBar, ButtonBase, DeviceConnectionModes, DeviceOfflineTypes, Flex, Hidden, Link, ListItem, Navigator, String, Tasker, withRegistration} from "@hps/hops-react";
import {Api} from "@hps/hops-sdk-js";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";

import dLogoutDialog from "Dispatchers/dLogoutDialog";
import withAuthUser from "Hoc/withAuthUser";
import withReducedFunctionality from "Hoc/withReducedFunctionality";

import SyncIconProgress from "@mui/icons-material/Autorenew";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import LogoutIcon from "@mui/icons-material/ExitToApp";
import CustomerServicesIcon from "@mui/icons-material/Favorite";
import HomeIcon from "@mui/icons-material/Home";
import UserIcon from "@mui/icons-material/Person";
import OnlineModeIcon from "@mui/icons-material/Public";
import OfflineSyncModeIcon from "@mui/icons-material/PublicOff";
import LocationIcon from "@mui/icons-material/Room";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import SyncErrorIcon from "@mui/icons-material/SyncDisabled";
import SyncProblemIcon from "@mui/icons-material/SyncProblem";
import WifiOffIcon from "@mui/icons-material/WifiOff";

import scss from "./UiBar.module.scss";


/**
 * App bar component
 *
 * @package HOPS
 * @subpackage Ui
 * @copyright Heritage Operations Processing Limited
 */
const UiBar = props => {

	const stringProps = {lineClamp: 1, noFlex: true};

	const uploadQueueLength = props.Orders?.UploadQueue?.length;
	const uploadQueueMaxRetries = props.Orders?.UploadQueue?.filter(q => q.RetryCount >= 10).length;

	const disconnected = props.OfflineType === DeviceOfflineTypes.Disconnected;

	/**
	 * Get the icon component to render.
	 * 
	 * @return {ReactNode}
	 */
	const syncIcon = () => {
		if (props.App?.Syncing) return <Animate animation="spin" component={SyncIconProgress} />;
		else if (uploadQueueMaxRetries) return <SyncErrorIcon />;
		else if (uploadQueueLength) return <SyncProblemIcon />;
		else return <CheckCircleIcon />;
	};

	const syncColour = () => {
		if (uploadQueueMaxRetries) return "error";
		else if (uploadQueueLength) return "warning";
		else return undefined;
	};

	const syncValue = () => {
		if (uploadQueueLength || uploadQueueMaxRetries) return "/uploadqueue";
		else return "sync";
	};

	const connectionModeIcon = () => {

		switch (props.DeviceConnectionMode) {
			case DeviceConnectionModes.Online:
				return <OnlineModeIcon />;
			case DeviceConnectionModes.OfflineSync:
				return <OfflineSyncModeIcon />;
			default:
				return <LocationIcon />;
		}

	};

	const handleHome = () => {
		Navigator.navigate("/");
	};

	const handleToggleButton = (e, value) => {
		switch (value) {
			case "logout":
				dLogoutDialog(true);
				return;
			case "sync":
				Tasker.runAll();
				return;
			default:
				Navigator.navigate((value || window.location.pathname));
		}
	};

	const centreMenu = {
		onChange: handleToggleButton,
		value: props.location.pathname,
		items: [
			{
				label: "Home",
				icon: <HomeIcon />,
				value: "/"
			},
			{
				label: "Search",
				icon: <SearchIcon />,
				value: "/search"
			},
			{
				label: "Customer Services",
				icon: <CustomerServicesIcon />,
				value: "/cs"
			}
		]
	};

	const rightMenu = {
		onChange: handleToggleButton,
		value: props.location.pathname,
		items: [
			{
				icon: <SettingsIcon />,
				value: "/device"
			},
			{
				color: syncColour(),
				label: `Sync ${uploadQueueLength ? ` (${uploadQueueLength})` : ""}`,
				icon: syncIcon(),
				value: syncValue()
			},
			{
				label: "Logout",
				icon: <LogoutIcon />,
				value: "logout"
			}
		]
	};

	return (
		<AppBar
			color={disconnected ? "error" : "primary"}
			centreMenu={centreMenu}
			rightMenu={rightMenu}>
			<Hidden hidden={disconnected}>
				{/* Hidden offline, the logo image URL will be un-retrievable! */ }
				<ButtonBase
					className={scss.orgButton}
					color="inherit"
					focusRipple={true}
					onClick={handleHome}
					size="large">
					<img
						alt=""
						className={scss.orgLogo}
						src={`${Api.baseURL}/uploads/railway_logos/${props.Registration?.Org?.Logo}`} />
				</ButtonBase>
			</Hidden>
			<Flex gap={0}>
				<ListItem icon={UserIcon}>
					<String bold={true} color="inherit" str={`${props.AuthUser?.Account?.Fname} ${props.AuthUser?.Account?.Sname}`} {...stringProps} />
				</ListItem>
				<ListItem icon={disconnected ? WifiOffIcon : connectionModeIcon}>
					<Link color="inherit" label={`${props.Registration?.Device?.Name} ${disconnected ? " (Offline)" : ""}`} StringProps={stringProps} uri="/device" />
				</ListItem>
			</Flex>
		</AppBar>
	);

};


export default connect(({Orders}) => ({Orders}))(withAuthUser(withReducedFunctionality(withRegistration(withRouter(UiBar)))));
