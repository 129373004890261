import {Store, withSnackbars} from "@hps/hops-react";
import {CheckoutBasketService, PaymentTypes, PosCustomerDetailsService} from "@hps/hops-sdk-js";
import {useEffect} from "react";
import {connect} from "react-redux";

import dBasketGoOffline from "Dispatchers/dBasketGoOffline";

const withBasket = Component => connect(({Basket}) => ({Basket}))(withSnackbars(
	props => {

		/**
		 * Total price of all items in the basket, after discounts
		 */
		const BasketTotal = CheckoutBasketService.getBasketTotal(
			(props.Basket?.Items || []),
			(props.Basket?.Discounts || [])
		);

		const BasketTotalDiscounts = props.Basket?.Discounts?.reduce((n, d) => (n + (d.Discount.Amount * d.DiscountQty)), 0);

		/**
		 * Allowed customer details fields
		 */
		const allowedCustomerDetails = PosCustomerDetailsService.resolveItemsAllowedCustomerDetails((props.Basket?.Items || []));

		/**
		 * Payments fields
		 */
		const subTotal = BasketTotal;

		const paymentsReceived = props.Basket?.Payments?.reduce((n, {TenderedAmount}) => n + TenderedAmount, 0);
		const paymentsValue = props.Basket?.Payments?.reduce((n, {Value}) => n + Value, 0);

		const paymentsBalanceDue = subTotal - paymentsValue;

		const paymentsChange = props.Basket?.Payments?.filter(p => !!p.PaymentProcessorData?.Change)
			.map(p => p.PaymentProcessorData)
			.reduce((n, {Change}) => n + Change, 0);

		const paymentsChangeIsDue = paymentsChange > 0;
		const paymentsIsSettled = paymentsValue === BasketTotal;

		const allowVoucherPayment = !props.Basket?.Payments?.find(payment => payment.PaymentType === (PaymentTypes.Cash || PaymentTypes.CardHolderPresent));

		const paymentsZeroValueBasket = (props.Basket?.Items?.length && BasketTotal === 0);

		// We don't need to expose all the Ui stuff as props from this component, just scope it as a local variable
		const Ui = Store.getState().Ui;

		/**
		 * A hook to maintain the reference to the App's offline state
		 * so we can control the basket offline state
		 */
		useEffect(() => {
			if (!Ui.OnlineApp) dBasketGoOffline();
		}, [Ui.OnlineApp]);


		return (
			<Component
				{...props}

				BasketCustomerDetails={props.Basket.CustomerDetails}
				BasketDiscounts={props.Basket.Discounts}
				BasketIdentity={props.Basket.Identity}
				BasketItems={props.Basket.Items}
				BasketLoading={props.Basket.Loading}
				BasketQuestions={props.Basket.Questions}
				BasketQuestionsAnswers={props.Basket.QuestionsAnswers}
				BasketQuestionsAnswersDeclined={props.Basket.QuestionsAnswersDeclined}
				OrderVouchers={props.Basket.OrderVouchers}
				Payments={props.Basket.Payments}
				PaymentsInProgress={props.Basket.PaymentsInProgress}
				PaymentsLoading={props.Basket.PaymentsLoading}

				allowedCustomerDetails={allowedCustomerDetails}
				AllowVoucherPayment={allowVoucherPayment}
				BasketTotal={BasketTotal}
				BasketTotalDiscounts={BasketTotalDiscounts}
				hasAllowedCustomerDetails={!!Object.keys(allowedCustomerDetails).length}
				hasPayments={props.Basket?.Payments?.length > 0}
				hasNoPayments={props.Basket?.Payments?.length === 0}
				PaymentsBalanceDue={paymentsBalanceDue}
				PaymentsChange={paymentsChange}
				PaymentsChangeIsDue={paymentsChangeIsDue}
				PaymentsIsSettled={paymentsIsSettled}
				PaymentsReceived={paymentsReceived}
				PaymentsValue={paymentsValue}
				PaymentsZeroValueBasket={paymentsZeroValueBasket} />
		);

	}
));

export default withBasket;
