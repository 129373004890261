import {Dialog, SnackbarService} from "@hps/hops-react";
import {ItemClaimErrorCodes, OrderableTypes, SeatReservationUtils, TicketSeatReservationModes} from "@hps/hops-sdk-js";
import {memo, useCallback, useEffect, useMemo, useState} from "react";

import SeatReservationEditor from "./SeatReservationEditor.js";
import SeatReservationEditorErrorDetails from "./SeatReservationEditorErrorDetails.js";
import SeatReservationService from "./SeatReservationService.js";

import scss from "./SeatReservationDialog.module.scss";

/**
 * Seat reservation editor dialog
 *
 * Allows editing the seats selected for an item in the order.
 *
 * Uses `SeatReservationEditor` internally. Validation and submission 
 * is however handled here so they can be conveniently triggered from 
 * the in-dialog controls, which are of course DOM-nested above the 
 * actual editor.
 *
 * @package HOPS
 * @subpackage SeatReservation
 * @author Heron Web Ltd
 * @copyright Heritage Operations Processing Limited
 */
const SeatReservationEditorDialog = memo(({open, onClose, onSubmitted, order, orderItem}) => {

	const [submitting, setSubmitting] = useState(false);
	const [errorData, setErrorData] = useState(null);

	/**
	 * Data fetched by `SeatReservationCheckoutUI`
	 */
	const [seatingData, setSeatingData] = useState(null);

	/**
	 * Get the order item's object
	 */
	const item = useMemo(() => {
		return order.Items.find(oi => (oi.Uuid === orderItem));
	}, [order, orderItem]);


	/**
	 * Get the item's seating details
	 */
	const reservationMode = item?.Orderable?.SeatReservationMode;
	const targetSeatCount = ((item?.Orderable?.People || 0) * (item?.Quantity || 0));

	/**
	 * Get the journey definitions that we need seating data for
	 */
	const targetJourneys = useMemo(() => {
		return (
			// Ticket options
			item?.Orderable?.Journeys ||
			// Ticket sessions
			item?.Orderable?.Trains.map(train => {
				return {
					Train: {
						Id: train.Id
					},
					ArrivalTrainSchedule: null,
					DepartureTrainSchedule: null
				};
			}) ||
			[]
		);
	}, [item]);

	/**
	 * Get the train journey IDs (strings) applicable to our item
	 */
	const targetJourneysTrainsIds = useMemo(() => {
		return targetJourneys.map(j => {
			return SeatReservationUtils.getTrainJourneyId(
				j.Train.Id,
				j.DepartureTrainSchedule?.Id,
				j.ArrivalTrainSchedule?.Id
			);
		});
	}, [targetJourneys]);

	/**
	 * Get the seat objects currently selected for the item
	 */
	const itemsExistingSeats = useMemo(() => {
		return order.Items.filter(oi => {
			return (
				(oi.OrderableType === OrderableTypes.SeatReservation) &&
				(oi.RelatedItemUuid === orderItem)
			);
		}).map(oi => oi.Orderable);
	}, [order, orderItem]);


	/**
	 * Selection state
	 */
	const [selection, setSelection] = useState({});
	const [knownReservations, setKnownReservations] = useState([]);


	/**
	 * Validate our current selection.
	 *
	 * @return {Boolean}
	 */
	const validateSelection = useCallback(() => {

		const selectedSeatCount = Object.values(selection).reduce((a, b) => (a + b.length), 0);
		const permittedSeatCount = (targetSeatCount * targetJourneys.length);

		if (TicketSeatReservationModes.requiresCustomerSelection(reservationMode)) {
			return (selectedSeatCount === permittedSeatCount);
		}
		else if (TicketSeatReservationModes.allowsCustomerSelection(reservationMode) || TicketSeatReservationModes.allowsRailwaySelection(reservationMode)) {
			return (selectedSeatCount <= permittedSeatCount);
		}
		else return false;

	}, [selection, reservationMode, targetJourneys, targetSeatCount]);


	/**
	 * Submitting the changes.
	 *
	 * @async
	 * @return {void}
	 */
	const handleSubmit = useCallback(async () => {

		const seats = [];

		/**
		 * Validate the selection
		 */
		if (!validateSelection()) {
			SnackbarService.snack("Please complete the selection.", "error");
			return;
		}

		/**
		 * Submitting!
		 */
		setErrorData(null);
		setSubmitting(true);

		/**
		 * Submit to the API
		 */
		try {

			/**
			 * Create our seat reservation objects
			 */
			for (const [journeyId, spaceIds] of Object.entries(selection)) {

				const {
					ArrivalTrainScheduleId,
					DepartureTrainScheduleId,
					TrainAssetId
				} = SeatReservationUtils.deconstructJourneyId(journeyId);

				for (const SpaceId of spaceIds) {
					seats.push({
						ArrivalTrainScheduleId,
						DepartureTrainScheduleId,
						TrainAssetId,
						SpaceId
					});
				}

			}

			/**
			 * Make the API call!
			 */
			await SeatReservationService.updateOrderItemSeatReservations(
				order.Id,
				item.Id,
				seats
			);

			/**
			 * We're done!
			 */
			onSubmitted();
			onClose();
			SnackbarService.snack("Saved seat reservations.", "success");

		}
		catch (e) {
			if (e?.response?.status === 406) {

				const errorData = e?.response?.data?.Errors;
				const unavailableSeats = errorData?.filter(e => (e.Id === ItemClaimErrorCodes.default.ItemUnfulfilable));

				if (errorData && (errorData?.length === unavailableSeats?.length)) {
					SnackbarService.snack(`Your selection could not be saved.\n${((unavailableSeats?.length > 1) ? `${unavailableSeats?.length} of the seats you selected have` : `${((seats.length > 1) ? "A" : "The")} seat you selected has`)} been reserved since you loaded this screen.`, "error");
				}
				else {
					setErrorData(errorData);
					SnackbarService.snack(`Your selection could not be saved.`, "error");
				}

				if (unavailableSeats?.length) {

					const updatedKnownReservations = [...knownReservations];
					const updatedSelection = {...selection};

					for (const unavailableSeat of unavailableSeats) {

						const {Seat, JourneyIdent} = unavailableSeat;
						const journey = SeatReservationUtils.deconstructJourneyId(JourneyIdent);

						updatedSelection[JourneyIdent] = updatedSelection[JourneyIdent].filter(s => (s !== Seat));
						if (updatedSelection[JourneyIdent].length === 0) {
							delete updatedSelection[JourneyIdent];
						}

						updatedKnownReservations.push({
							TrainAsset: journey.TrainAssetId,
							DepartureTrainSchedule: journey.DepartureTrainScheduleId,
							ArrivalTrainSchedule: journey.ArrivalTrainScheduleId,
							Space: Seat
						});

					}

					setSelection(updatedSelection);
					setKnownReservations(updatedKnownReservations);

				}

			}
			else SnackbarService.snack(e);
		}

		/**
		 * Done!
		 */
		setSubmitting(false);

	}, [item, order, onClose, onSubmitted, selection, knownReservations, validateSelection]);


	/**
	 * Carry out initial seating selection once API data is available
	 *
	 * This is so we can deselect seats that are in the order but no 
	 * longer applicable e.g. due to train/asset replacement or seat 
	 * plan modification. These orphaned seats have to be removed 
	 * from the selection so they don't count towards the number 
	 * of selected seats at validation time.
	 */
	useEffect(() => {
		if (seatingData && open) {

			const selection = {};
			const allTrainAssetsInSeatingPlans = seatingData.Trains.map(t => t.Assets).flat().map(ta => ta.Id);
			const allSpaceIdsInSeatingPlans = seatingData.SeatPlans.map(s => s.Spaces).flat().map(s => s.Id);

			for (const seat of (itemsExistingSeats || [])) {

				const spaceId = seat.Space?.Id;
				const trainAssetId = seat.TrainAsset?.Id;

				/**
				 * Make sure the train asset and space are still 
				 * part of the selection (see also below etc.)
				 */
				if (allTrainAssetsInSeatingPlans.includes(trainAssetId) &&
					allSpaceIdsInSeatingPlans.includes(spaceId)) {

					const trainJourneyId = SeatReservationUtils.getTrainJourneyId(
						seat.TrainAsset?.Train?.Id,
						seat.DepartureTrainSchedule,
						seat.ArrivalTrainSchedule
					);

					/**
					 * Make sure this journey is still part of the selection
					 *
					 * If not, the train/asset has been replaced so we must 
					 * ensure the seat is not selected - a new one must be 
					 * picked, and if we didn't deselect this one then we'd 
					 * consequently end up with too many selected overall.
					 */
					if (targetJourneysTrainsIds.includes(trainJourneyId)) {
						const journeyId = SeatReservationUtils.getJourneyId(
							trainAssetId,
							seat.DepartureTrainSchedule,
							seat.ArrivalTrainSchedule
						);
						selection[journeyId] ||= [];
						selection[journeyId].push(spaceId);
					}

				}

			}

			setKnownReservations([]);
			setSelection(selection);

		}
	}, [open, seatingData, itemsExistingSeats, targetJourneysTrainsIds]);


	/**
	 * Render!
	 */
	return (
		<Dialog
			classNameActions={scss.dialogActions}
			classNameTitle={scss.dialogTitle}
			fullScreen
			loading={submitting}
			okLabel="Save"
			open={open}
			onClose={onClose}
			onOk={handleSubmit}
			title="Edit Seat Reservations">
			{(errorData && <SeatReservationEditorErrorDetails errorData={errorData} />)}
			<SeatReservationEditor
				knownReservations={knownReservations}
				onChangeSelection={setSelection}
				onFetchedSeatingData={setSeatingData}
				order={order}
				orderItem={orderItem}
				reservationMode={reservationMode}
				selection={selection}
				targetJourneys={targetJourneys}
				targetSeatCount={targetSeatCount} />
		</Dialog>
	);

});

export default SeatReservationEditorDialog;
