import {SeatReservationCheckoutUi, SnackbarService} from "@hps/hops-react";
import {CheckoutBasketItem, ItemClaimErrorCodes, OrderableTypes} from "@hps/hops-sdk-js";
import React from "react";

import dBasketClaimData from "Dispatchers/dBasketClaimData.js";
import BasketService from "Services/BasketService.js";

/**
 * Seat reservation handler component
 *
 * The main component allowing seat reservations to be made and viewed.
 * 
 * @package HOPS
 * @subpackage SeatReservation
 * @copyright Heritage Operations Processing Limited
 */
const SeatReservationHandler = props => {

	/**
	 * Get the `BasketItem` in the local checkout basket corresponding 
	 * to a given seat reservation by its train and space IDs.
	 *
	 * @param {Integer} trainAssetId
	 * @param {?Integer} trainScheduleIdDeparture
	 * @param {?Integer} trainScheduleIdArrival
	 * @param {Integer} spaceId
	 * @return {Object|undefined} `CheckoutBasketItem`-like object
	 */
	const getSeatBasketItem = (trainAssetId, trainScheduleIdDeparture, trainScheduleIdArrival, spaceId) => {
		return this.props.items.find(({Item, OrderableType}) => {
			return (
				(OrderableType === OrderableTypes.SeatReservation) &&
				(Item?.TrainAsset?.Id === trainAssetId) &&
				(Item?.Space?.Id === spaceId) &&
				(Item?.DepartureTrainSchedule === trainScheduleIdDeparture) &&
				(Item?.ArrivalTrainSchedule === trainScheduleIdArrival)
			);
		});
	};


	/**
	 * A seat was added to the selection.
	 *
	 * @param {Object} train
	 * @param {Object} trainAsset
	 * @param {?Integer} trainScheduleIdDeparture
	 * @param {?Integer} trainScheduleIdArrival
	 * @param {Object} space
	 * @param {Object} relatedBasketItem
	 * @return {Boolean|ItemClaimErrorCode}
	 */
	const handleSpaceAdded = async (train, trainAsset, trainScheduleIdDeparture, trainScheduleIdArrival, space, relatedBasketItem) => {

		let error = false;

		/**
		 * Reserve the seat
		 */
		try {

			/**
			 * Basket items to add
			 */
			const items = [
				CheckoutBasketItem.construct({
					RelatedItemUuid: relatedBasketItem.Uuid,
					OrderableType: OrderableTypes.SeatReservation,
					Item: {
						Train: train,
						TrainAsset: trainAsset,
						Space: space,
						DepartureTrainSchedule: trainScheduleIdDeparture,
						ArrivalTrainSchedule: trainScheduleIdArrival
					},
					Price: 0,
					Quantity: 1,
					VatProportion: 0,
					VatRate: 0
				})
			];

			/**
			 * Add to the basket
			 */
			const result = await BasketService.addItems(
				items,
				{dispatchClaimData: false, snackOnError: false}
			);

			/**
			 * There was an error claiming the item
			 */
			if (result?.Errors?.length) {

				error = true;
				const errorCode = result?.Errors[0]?.Code;
				const unfulfilableIcEc = ItemClaimErrorCodes.default.ItemUnfulfilable;

				/**
				 * The item's already been claimed
				 */
				if (errorCode === unfulfilableIcEc) {
					SnackbarService.snack("The space has now been booked by another customer.", "error");
					return new ItemClaimErrorCodes.ItemClaimErrorCode(unfulfilableIcEc);
				}
				else dBasketClaimData({Items: items, Result: result});

				return false;

			}

			return !!result;

		}

		/**
		 * Reservation error
		 */
		catch (e) {
			error = true;
			SnackbarService.snack(e);
		}

		/**
		 * Throw now (this is caught by `SeatReservationCheckoutUi` 
		 * and will result in the seat being removed from the local 
		 * (visual) selection so it no longer looks selected).
		 */
		if (error) {
			throw new Error();
		}

		return true;

	};


	/**
	 * A seat ID was removed from the selection.
	 *
	 * @param {Integer} trainAssetId
	 * @param {?Integer} trainScheduleIdDeparture
	 * @param {?Integer} trainScheduleIdArrival
	 * @param {Integer} spaceId
	 * @return {Boolean}
	 */
	const handleSpaceRemoved = async (trainAssetId, trainScheduleIdDeparture, trainScheduleIdArrival, spaceId) => {

		try {

			const basketItem = getSeatBasketItem(trainAssetId, trainScheduleIdDeparture, trainScheduleIdArrival, spaceId);

			if (basketItem) {
				const result = await BasketService.removeItems([basketItem], {snackOnError: false});
				return !!result;
			}

			else throw new Error("Couldn't match the seat reservation in the basket.");

		}

		catch (e) {

			SnackbarService.snack(e);

			/**
			 * Rethrow the error - it'll be caught by
			 * `SeatReservationCheckoutUi` to add the 
			 * seat back to the visual selection.
			 */
			throw e;

		}

	};


	const targetBasketItem = props.targetBasketItem;

	if (targetBasketItem) {
		return (
			<SeatReservationCheckoutUi.Component
				initialSelection={SeatReservationCheckoutUi.mapBasketItemsToSelectionState(props.items.filter(i => (i.RelatedItemUuid === props.targetBasketItem.Uuid)))}
				onAddSelection={handleSpaceAdded}
				onRemoveSelection={handleSpaceRemoved}
				targetBasketItem={targetBasketItem} />
		);
	}
	else return null;

};

export default SeatReservationHandler;
