import {SnackbarService} from "@hps/hops-react";
import {SeatReservationUtils} from "@hps/hops-sdk-js";
import {memo, useCallback, useMemo} from "react";

import dSeatReservationDialog from "Dispatchers/dSeatReservationDialog.js";
import withBasket from "Hoc/withBasket.js";
import withUi from "Hoc/withUi.js";

import SeatReservationDialog from "./SeatReservationDialog.js";


export default withBasket(withUi(memo(props => {

	const {BasketItems, Ui} = props;

	const targetBasketItemUuid = Ui.SeatReservationDialog;

	/**
	 * Get the relevant basket item object
	 */
	const targetBasketItem = useMemo(() => BasketItems.find(i => (i.Uuid === targetBasketItemUuid)), [BasketItems, targetBasketItemUuid]);


	/**
	 * Close the dialog.
	 *
	 * @return {void}
	 */
	const handleClose = useCallback(() => {
		dSeatReservationDialog(null);
	}, []);


	/**
	 * Close the dialog.
	 *
	 * The user needs to have a valid selection first.
	 * 
	 * @return {void}
	 */
	const handleSubmit = useCallback(() => {
		if (SeatReservationUtils.validateBasketItemSelection(targetBasketItem, BasketItems)) {
			handleClose();
		}
		else SnackbarService.snack("Please complete the selection.", "error");
	}, [handleClose, targetBasketItem, BasketItems]);


	/**
	 * Render!
	 */
	return (
		<SeatReservationDialog
			items={props.BasketItems}
			onClose={handleClose}
			onSubmit={handleSubmit}
			open={!!props.Ui.SeatReservationDialog}
			targetBasketItem={targetBasketItem} />
	);

})));
